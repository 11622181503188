























import { Vue, Component } from 'vue-property-decorator';
import { placeModule } from '@/store';

@Component
export default class PasswordReset extends Vue {
  public isLoading: boolean = false;
  public password: string = '';
  public passwordCheck: string = '';
  public focusState: string = '';
  public passwordType: string = 'password';
  public passwordCheckType: string = 'password';
  public passwordText: '표시' | '숨김' = '표시';
  public passwordCheckText: '표시' | '숨김' = '표시';
  public $refs!: {
    password: HTMLInputElement,
    passwordCheck: HTMLAnchorElement
  }

  public passwordDelete() {
    this.password = '';
    this.$refs.password.focus();
  }
  public passwordCheckDelete() {
    this.passwordCheck = '';
    this.$refs.passwordCheck.focus();
  }
  public passwordDisplay() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
      this.passwordText = '숨김';
    } else {
      this.passwordType = 'password';
      this.passwordText = '표시';
    }
    this.$refs.password.focus();
  }
  public passwordCheckDisplay() {
    if (this.passwordCheckType === 'password') {
      this.passwordCheckType = 'text';
      this.passwordCheckText = '숨김';
    } else {
      this.passwordCheckType = 'password';
      this.passwordCheckText = '표시';
    }
    this.$refs.passwordCheck.focus();
  }
  get isDisabled() {
    if (this.password.length > 5 && this.password === this.passwordCheck) {
      return false;
    } else {
      return true;
    }
  }

  get encKey() {
    return this.$route.query.enc_key;
  }

  public onSubmit() {
    this.isLoading = true;

    placeModule.fetchEmailPasswordReset({
      enc_key: this.encKey,
      password: this.passwordCheck
    }).then((res) => {
      if (res.status) {
        this.$router.push('/password/reset/final');
      }
    });
    this.isLoading = false;
  }
}
